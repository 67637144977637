import React from 'react';
import './about.css';
import US from '../../assets/about_us.png';

const About = () => {
  return (
    <section id='about'>
      <h2 >About Us</h2>
      <div className='about_container'>
        <div className='about_box'>
          <div className='about_us'>
            <div className='about_us-image'>
              <img src={US} alt='About Image' />
            </div>
          </div>
          <div className='about_content'>
            <h3>Welcome to Elite FM Solutions</h3>
            <p className='about-text'>
              A family-run business with over 20 years of combined experience in Construction, Facility Management, and Health & Safety. Our passion for delivering exceptional service and quality drives us to exceed your expectations in every project.
            </p>

            <h3>Our Team of Experts</h3>
            <p className='about-text'>
              Our team of skilled engineers and consultants, based across the UK, offers a wealth of knowledge and a personal approach to your business needs. No matter how big or small the task, we're here to help.
            </p>

            <h3>Building Lasting Relationships</h3>
            <p className='about-text'>
              We believe in building strong relationships with our clients, founded on trust, reliability, and outstanding service. Our goal is to create safer, more efficient environments that enhance your business.
            </p>

            <h3>Contact Us Today</h3>
            <p className='about-text'>
              For more information or to discuss your requirements, please don't hesitate to get in touch. Your satisfaction is our top priority.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
