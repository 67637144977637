import React from 'react';
import './services.css';

const servicesData = [
  {
    title: 'Facility Management',
    description: 'Elite FM Solutions delivers comprehensive Facility Management services to ensure your buildings and infrastructure operate at peak efficiency. Our expert team employs cutting-edge technology to monitor system performance, manage energy consumption, and maintain stringent safety standards. From routine maintenance to emergency response planning, we create environments that boost productivity and well-being.',
  },
  {
    title: 'Construction',
    description: 'Our Construction services combine quality craftsmanship with timely delivery across all project phases. From pre-construction planning to post-construction support, we specialise in creating structures that surpass industry standards whilst ensuring full regulatory compliance. Our skilled professionals tackle complex construction challenges with innovative solutions, delivering enduring projects.',
  },
  {
    title: 'Compliance',
    description: 'Simplify the complexities of regulatory requirements with our expert Compliance services. We provide comprehensive guidance on local codes, industry standards, environmental regulations, and safety protocols to ensure your operations remain fully compliant. By anticipating legislative changes and implementing best practices, we safeguard your business against potential risks.',
  },
  {
    title: 'Digital Innovation',
    description: 'Embrace the future with our Digital Innovation services, integrating cutting-edge technologies into your operations. From AI-driven process automation to leveraging data analytics for informed decision-making, our digital solutions enhance efficiency and unlock new growth opportunities. Let us guide your transformation with forward-thinking strategies tailored for the digital age.',
  },
  {
    title: 'Solar',
    description: 'Our Solar services make the transition to sustainable energy seamless and rewarding. We design and install high-performance solar power systems customised to your specific energy needs, maximising your return on investment through long-term utility cost savings. Empower your organisation with clean energy solutions that benefit both your bottom line and the environment.',
  },
  {
    title: 'Charging Solutions',
    description: "As electric vehicles gain prominence, our expert Charging Solutions ensure you're ahead of the curve. We provide comprehensive charging station installations for both private and public spaces, enhancing accessibility and convenience for EV users. Our solutions support eco-friendly transportation initiatives whilst future-proofing your infrastructure.",
  },
  {
    title: 'Sprinkler Systems',
    description: 'Safety and efficiency are paramount in our Sprinkler Systems services. We design, install, and maintain advanced sprinkler systems that provide reliable fire protection for your facilities. Our bespoke solutions are tailored to meet the specific needs of your buildings, ensuring compliance with safety regulations and offering peace of mind through robust fire prevention measures.',
  },
];

const Services = () => {
  return (
    <section id='services'>
      <h2>Our Services</h2>
      <div className='container services_container'>
        {servicesData.map((service, index) => (
          <div
            className={`service_card ${index % 2 === 0 ? 'even' : 'odd'}`}
            key={index}
          >
            <h3>{service.title}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Services;