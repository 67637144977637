import React, { useState } from 'react'
import Header from './components/header/Header'
import About from './components/about/About'
import Services from './components/services/Services'
import Contact from './components/contact/Contact'
import Footer from './components/footer/Footer'
import Themes from './components/themes/Themes'


const App = () => {
      
  return (
    <div>
      <>
          {/* <Themes /> */}
          <Header />
          <About />
          <Services />
          <Contact />
          <Footer />
      </>
    </div>
    
  )
}

export default App
