import React, { useState, useEffect } from 'react';
import './header.css';
import header_image from '../../assets/header_image.png';
import CTA from './CTA';
import Nav from '../../components/nav/Nav';

const Header = () => {
  const [text, setText] = useState('');
  const fullText = 'Welcome!';
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (index < fullText.length) {
      const timeout = setTimeout(() => {
        setText(prevText => prevText + fullText[index]);
        setIndex(prevIndex => prevIndex + 1);
      }, 200); // Adjust timing here (150ms between each character)

      return () => clearTimeout(timeout);
    } else {
      const timeout = setTimeout(() => {
        setText('');
        setIndex(0);
      }, 4000); // Wait for 2 seconds before restarting

      return () => clearTimeout(timeout);
    }
  }, [index, fullText]);

  return (
    <header>
      <Nav />
      <div className="container header_container">
        <div className="photo-frame">
          <img src={header_image} alt="Company Logo" />
          <div className="typing-text">{text}</div>
        </div>
        <h3 className="text-light">
          <strong>
            <span style={{ fontSize: '22px' }}>Elite FM Solutions</span>
          </strong>
        </h3>
        <h4 className="text-light">
          Building better spaces together
        </h4>
        <br />
        <br />
        <CTA />
      </div>
    </header>
  );
};

export default Header;