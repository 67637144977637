import React, { useState, useEffect } from 'react';
import './nav.css';
import { AiFillHome, AiFillInfoCircle, AiFillTool, AiFillContacts, AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';
import logo_image from '../../assets/logo.svg'; // Update with the correct path to your logo image

const Nav = () => {
  const [activeNav, setActiveNav] = useState('#');
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const sections = ['#', 'about', 'services', 'contact'];

      for (let i = sections.length - 1; i >= 0; i--) {
        const section = sections[i];
        const element = document.getElementById(section === '#' ? 'home' : section);
        if (element) {
          const rect = element.getBoundingClientRect();
          if (rect.top <= windowHeight / 2 && rect.bottom >= windowHeight / 2) {
            setActiveNav('#' + section);
            break;
          }
        }
      }

      // Special case for home section
      if (scrollPosition < windowHeight / 2) {
        setActiveNav('#');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleNavClick = (section) => {
    setActiveNav(section);
    setMenuOpen(false); // Close the menu after clicking a link on small screens
  };

  return (
    <nav>
      <a href='#' onClick={() => handleNavClick('#')} className='logo'>
        <img src={logo_image} alt='Logo' />
      </a>
      <div className={`nav-links ${menuOpen ? 'open' : ''}`}>
        <a
          href='#'
          onClick={() => handleNavClick('#')}
          className={activeNav === '#' ? 'active' : ''}
        >
          <AiFillHome /> <span>Home</span>
        </a>
        <a
          href='#about'
          onClick={() => handleNavClick('#about')}
          className={activeNav === '#about' ? 'active' : ''}
        >
          <AiFillInfoCircle /> <span>About</span>
        </a>
        <a
          href='#services'
          onClick={() => handleNavClick('#services')}
          className={activeNav === '#services' ? 'active' : ''}
        >
          <AiFillTool /> <span>Services</span>
        </a>
        <a
          href='#contact'
          onClick={() => handleNavClick('#contact')}
          className={activeNav === '#contact' ? 'active' : ''}
        >
          <AiFillContacts /> <span>Contact</span>
        </a>
      </div>
      <div className="menu-toggle" onClick={toggleMenu}>
        {menuOpen ? <AiOutlineClose /> : <AiOutlineMenu />}
      </div>
    </nav>
  );
};

export default Nav;