import React from 'react';
import './contact.css';
import { MdOutlineEmail } from 'react-icons/md';
import { FaPhone, FaInstagram, FaTwitter, FaFacebook } from "react-icons/fa";
import { IoHome } from "react-icons/io5";
import CONTACT_IMAGE from '../../assets/contact_us.png';

const Contact = () => {
  return (
    <section id='contact'>
      <h2>Contact Us</h2>
      <div className='contact_container'>
        <div className='contact_box'>
          <div className='contact_us'>
            <div className='contact_us-image'>
              <img src={CONTACT_IMAGE} alt='Contact Us' />
            </div>
          </div>
          <div className='contact_content'>
            <div className='contact_option'>
              <IoHome className='contact_option-icon' />
              <h3>Elite FM Solutions</h3>
              <p className='contact-text'>
                82, Suite A, James Carter Road<br />
                Midenhall, IP28 7DE
              </p>
              <h3>Office Hours:</h3>
              <p className='contact-text'>
                Monday to Friday<br />
                9am to 5pm
              </p>
            </div>
            <div className='contact_option'>
              <FaPhone className='contact_option-icon' />
              <h3>Call Us</h3>
              <p className='contact-text'>01284 245 584</p>
            </div>
            <div className='contact_option'>
              <MdOutlineEmail className='contact_option-icon' />
              <h3>Email</h3>
              <p className='contact-text'>
                <a href='mailto:info@elitefmsolutions.co.uk'>info@elitefmsolutions.co.uk</a>
              </p>
            </div>
            {/* <div className='social_media'>
              <h3>Social Media</h3>
              <div className='social_buttons'>
                <a href="https://www.instagram.com/elitefmsolutions" target="_blank" rel="noopener noreferrer" className='social_button'>
                  <FaInstagram />
                </a>
                <a href="https://www.twitter.com/elitefmsolutions" target="_blank" rel="noopener noreferrer" className='social_button'>
                  <FaTwitter />
                </a>
                <a href="https://www.facebook.com/elitefmsolutions" target="_blank" rel="noopener noreferrer" className='social_button'>
                  <FaFacebook />
                </a>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;