import React from 'react';
import './footer.css';

const Footer = () => {
  return (
    <footer className="main-footer"> {/* Added the "main-footer" class */}

      <p className='permalinks_two'>
        Copyright &copy; 2024 Elite FM Solutions. All Rights Reserved.
      </p>

      <a href='https://evolv.org.uk/' target='_blank' className='permalinks_two'>
        <strong><span>Website built by: eVOLV</span></strong>
      </a>
    </footer>
  );
}

export default Footer;