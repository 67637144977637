import React from 'react';
import brochure from '../../assets/brochure.pdf';
import Themes from '../../components/themes/Themes'

const CTA = () => {
  return (
    <div className='cta'>
      <a href='#services' className='btn btn-primary'>Learn More</a>
      {/* <Themes /> */}
    </div>
  );
}

export default CTA;
